<div class="kt-login__forgot">
  <div class="kt-login__head">
    <h3 class="kt-login__title">{{ "AUTH.FORGOT.TITLE" | translate }}</h3>
    <div class="kt-login__desc">Enter your email to reset your password:</div>
  </div>

  <div class="kt-login__form">
    <form class="kt-form" [formGroup]="forgotPasswordForm" autocomplete="off">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.EMAIL" | translate }}</mat-label>
          <input
            matInput
            type="email"
            placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
            formControlName="email"
            autocomplete="on"
          />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>{{ "AUTH.VALIDATION.INVALID_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minLength')">
            <strong
              >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxLength')">
            <strong
              >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 320</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div class="kt-login__actions">
        <button
          routerLink="/"
          [disabled]="loading"
          id="kt_login_signup_cancel"
          class="btn btn-outline-brand btn-pill"
        >
          {{ "AUTH.GENERAL.BACK_BUTTON" | translate }}
        </button>
        <button
          (click)="submit()"
          id="kt_login_signin_submit"
          class="btn btn-brand btn-pill btn-elevate"
          [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
              loading
          }"
        >
          {{ "AUTH.GENERAL.SUBMIT_BUTTON" | translate }}
        </button>
      </div>
      <!--end::Action-->
    </form>
  </div>
</div>
