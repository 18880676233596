<!--begin::Signin-->
<div class="kt-login__signin">
  <div class="kt-login__head">
    <h3 class="kt-login__title">Sign In</h3>
  </div>

  <!--begin::Form-->
  <form class="kt-form" [formGroup]="loginForm" autocomplete="off">
    <div class="form-group">
      <mat-form-field>
        <mat-label>{{ "AUTH.INPUT.EMAIL" | translate }}</mat-label>
        <input
          matInput
          type="email"
          placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
          formControlName="email"
          autocomplete="on"
        />
        <mat-error *ngIf="isControlHasError('email', 'required')">
          <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="isControlHasError('email', 'minLength')">
          <strong
            >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
          >
        </mat-error>
        <mat-error *ngIf="isControlHasError('email', 'maxLength')">
          <strong
            >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 50</strong
          >
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>{{ "AUTH.INPUT.PASSWORD" | translate }}</mat-label>
        <input
          matInput
          type="password"
          placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
          formControlName="password"
          autocomplete="on"
        />
        <mat-error *ngIf="isControlHasError('password', 'required')">
          <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="isControlHasError('password', 'minLength')">
          <strong
            >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
          >
        </mat-error>
        <mat-error *ngIf="isControlHasError('password', 'maxLength')">
          <strong
            >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</strong
          >
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row kt-login__extra">
      <div class="col">
        <label class="kt-checkbox">
          <input type="checkbox" name="remember" /> Remember me
          <span></span>
        </label>
      </div>
      <div class="col kt-align-right">
        <a
          *ngIf="!!loginForm.value.email"
          href="javascript:;"
          [routerLink]="['/auth/forgot']"
          [state]="{ email: loginForm.value.email }"
          class="kt-login__link"
        >
          <!-- <a *ngIf="!!loginForm.value.email" href="javascript:;" [routerLink]="['/auth/forgot']" [queryParams]="{ email: loginForm.value.email }" class="kt-login__link"> -->
          {{ "AUTH.GENERAL.FORGOT_BUTTON" | translate }}
        </a>
        <a
          *ngIf="!loginForm.value.email"
          href="javascript:;"
          routerLink="/auth/forgot"
          class="kt-login__link"
        >
          {{ "AUTH.GENERAL.FORGOT_BUTTON" | translate }}
        </a>
      </div>
    </div>
    <!--begin::Action-->
    <div class="kt-login__actions">
      <button
        (click)="submit()"
        [ngClass]="{
          'kt-spinner kt-spinner--center kt-spinner--md kt-spinner--light':
            loading
        }"
        id="kt_login_signin_submit"
        class="btn btn-instagram"
      >
        {{ "AUTH.LOGIN.BUTTON" | translate }}
      </button>
    </div>
    <!--end::Action-->
  </form>
  <!--end::Form-->
</div>
<!--end::Signin-->
<!--
<div class="kt-login__account">
	<span class="kt-login__account-msg">
		Don't have an account yet ?
	</span>
	&nbsp;&nbsp;
	<a href="javascript:;" routerLink="/signup"
		class="kt-link kt-login__signup-link">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</a>
</div> -->
