<div
  class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v6 kt-login--signin"
  id="kt_login"
>
  <div
    class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
  >
    <div
      class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
    >
      <div class="kt-login__wrapper">
        <div class="kt-login__container">
          <div class="kt-login__body">
            <div class="kt-login__logo">
              <a href="#">
                <img
                  class="logo-img"
                  src="./assets/media/company-logos/logo-brand-rvpfactory-v2.png"
                />
              </a>
            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
    <!--end::Content-->
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
      style="
        background-image: url(./assets/media/bg/bg.jpg);
        background-size: 100% 100%;
      "
    >
      <div class="kt-login__section">
        <div class="kt-login__block">
          <h3 class="kt-login__title">Welcome to the Factory App</h3>
          <div class="kt-login__desc">
            Streamline the factory workflow, manage orders, storage and much
            more.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
