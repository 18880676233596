import { AbstractControl } from '@angular/forms';

export class PasswordValidator {
  static MatchPassword(control: AbstractControl) {
    const password = control.get('password')?.value;

    const confirmPassword = control.get('confirmPassword')?.value;

    if (password !== confirmPassword) {
      control.get('confirmPassword')?.setErrors({ ConfirmPassword: true });
      return true;
    } else {
      return false;
    }
  }

  static Strength(control: AbstractControl) {
    if (!/[a-zA-Z]/.test(control.value)) {
      return { passwordStrength: true };
    }
    return null;
  }
}
