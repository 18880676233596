<div class="kt-login__forgot">
  <div class="kt-login__head">
    <h3 class="kt-login__title">{{ "AUTH.RESET.TITLE" | translate }}</h3>
    <div class="kt-login__desc">Enter your new password:</div>
  </div>

  <div class="kt-login__form">
    <form class="kt-form" [formGroup]="resetPasswordForm" autocomplete="off">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.PASSWORD" | translate }}</mat-label>
          <input
            matInput
            type="password"
            placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
            formControlName="password"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minLength')">
            <strong
              >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxLength')">
            <strong
              >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'passwordStrength')">
            <strong
              >Password strength is too weak. At least 1 character is
              required.</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.CONFIRM_PASSWORD" | translate }}</mat-label>
          <input
            matInput
            type="password"
            placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}"
            formControlName="confirmPassword"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('confirmPassword', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword', 'minLength')">
            <strong
              >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword', 'maxLength')">
            <strong
              >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</strong
            >
          </mat-error>
          <mat-error
            *ngIf="isControlHasError('confirmPassword', 'passwordStrength')"
          >
            <strong
              >Password strength is too weak. At least 1 character is
              required.</strong
            >
          </mat-error>
          <mat-error *ngIf="isPasswordsMatch()">
            <strong>Passsword and ConfirmPassword didn't match.</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div class="kt-login__actions">
        <!-- <button routerLink="/" [disabled]="loading" id="kt_login_signup_cancel"
					class="btn btn-outline-brand btn-pill">{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}</button> -->
        <button
          (click)="submit()"
          id="kt_login_signin_submit"
          class="btn btn-brand btn-pill btn-elevate"
          [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
          }"
        >
          {{ "AUTH.GENERAL.SUBMIT_BUTTON" | translate }}
        </button>
      </div>
      <!--end::Action-->
    </form>
  </div>
</div>
