// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthService } from '../../../../core/auth';
import { PasswordValidator } from '../register/confirm-password.validator';
import { NotificationService } from '@coreservices';

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private notificationService = inject(NotificationService);
  // Public params
  resetPasswordForm!: UntypedFormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.initRegistrationForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
    this.loading = false;
  }

  initRegistrationForm() {
    this.resetPasswordForm = this.fb.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
        PasswordValidator.Strength,
      ])
      ],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
        PasswordValidator.Strength,
      ])
      ]
    }, {
      validator: PasswordValidator.MatchPassword
    });
  }

  submit() {
    const controls = this.resetPasswordForm.controls;
    /* check form */
    if (this.resetPasswordForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

    const password = controls['password'].value;
    const token = this.route.snapshot.params['token'];
    this.authService.requestResetPassword(password, token).pipe(
      tap(response => {
        if (response && response.success) {
          this.notificationService.showInfo(this.translate.instant('AUTH.REGISTER.SUCCESS'));
          this.router.navigateByUrl('auth/login');
        } else {
          this.notificationService.showError(this.translate.instant('AUTH.REGISTER.SUCCESS'));
        }
      }),
      takeUntil(this.unsubscribe),
      finalize(() => {
        this.loading = false;
        this.cdr.markForCheck();
      })
    ).subscribe();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) &&
      (control.dirty || control.touched);
    return result;
  }

  isPasswordsMatch(): boolean {
    return this.resetPasswordForm.hasError('ConfirmPassword');
  }
}
