import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Store, } from '@ngrx/store';

import { environment } from '../../../../../environments/environment';
import { AppState } from '../../../../core/reducers';
import { Login, } from '../../../../core/auth';
import { NotificationService } from '@core/services';
import { AuthService } from '@coreauth/_services';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  private notificationService = inject(NotificationService);
  loginForm!: UntypedFormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<any>;
  private returnUrl: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit(): void {
    localStorage.removeItem(environment.authTokenKey);
    this.initLoginForm();

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
      this.returnUrl = '/'; // params['returnUrl'] || '/';
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
    this.loading = false;
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
      ],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])
      ]
    });
  }

  submit() {
    const controls = this.loginForm.controls;
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

    const authData = {
      email: controls['email'].value,
      password: controls['password'].value
    };
    this.auth
      .login(authData.email, authData.password)
      .pipe(
        tap(data => {
          this.store.dispatch(new Login({ authToken: data.token, isAdmin: false, user: data.user }));
          this.router.navigateByUrl(this.returnUrl);
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  /*
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
