import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { Login } from '@coreauth/_actions/auth.actions';
import { AppState } from '../../../../core/reducers';
import { AuthService, } from '../../../../core/auth';
import { PasswordValidator } from './confirm-password.validator';
import { NotificationService } from '@core/services/ui';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm!: UntypedFormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<any>;

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.initRegisterForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
    this.loading = false;
  }

  initRegisterForm() {
    this.registerForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)
          ])
        ],
        confirmPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)
          ])
        ],
      }, {
      validator: PasswordValidator.MatchPassword
    });
  }

  submit() {
    const controls = this.registerForm.controls;

    if (this.registerForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;
    const password = controls['password'].value;
    const token = this.route.snapshot.params['token'];
    this.auth.register(password, token).pipe(
      tap(user => {
        if (user) {
          this.store.dispatch(new Login({ authToken: token, isAdmin: true }));
          this.notification.showInfo(this.translate.instant('AUTH.REGISTER.SUCCESS'));
          this.router.navigateByUrl('/');
        } else {
          this.notification.showError(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'));
        }
      }),
      takeUntil(this.unsubscribe),
      finalize(() => {
        this.loading = false;
        this.cdr.markForCheck();
      })
    ).subscribe();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  isPasswordsMatch(): boolean {
    return this.registerForm.hasError('ConfirmPassword');
  }
}
