// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { NotificationService } from '@core/services';
import { AuthService } from '../../../../core/auth';

@Component({
  selector: 'kt-forgot-password',
  templateUrl: './forgot-password.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private notificationService = inject(NotificationService);
  // Public params
  forgotPasswordForm!: UntypedFormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.initRegistrationForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
    this.loading = false;
  }

  initRegistrationForm() {
    const userLogin = history.state.email;
    this.forgotPasswordForm = this.fb.group({
      email: [userLogin || '', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
      ])
      ]
    });
  }

  submit() {
    const controls = this.forgotPasswordForm.controls;
    /* check form */
    if (this.forgotPasswordForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

    const email = controls['email'].value;
    this.authService.requestPassword(email).pipe(
      tap(response => {
        if (response && response.success) {
          this.notificationService.showInfo(this.translate.instant('AUTH.FORGOT.SUCCESS'));
          this.router.navigateByUrl('/');
        } else {
          this.notificationService
            .showError(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', {
              name: this.translate.instant('AUTH.INPUT.EMAIL')
            }));
        }
      }),
      takeUntil(this.unsubscribe),
      finalize(() => {
        this.loading = false;
        this.cdr.markForCheck();
      })
    ).subscribe();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.forgotPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) &&
      (control.dirty || control.touched);
    return result;
  }
}
